.basics-page {
    padding: 24px;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    backdrop-filter: blur(10px);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.37);
    text-align: center;
    width: 80%;
    max-width: 800px;
    margin: 24px auto;
}

.basics-page h1 {
    font-size: 36px;
    margin-bottom: 16px;
    color: #5272f6;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.basics-page p {
    font-size: 18px;
    color: #b0b0b0;
}

.interaction {
    margin-top: 24px;
}

.question h2 {
    font-size: 24px;
    margin-bottom: 8px;
}

.answer textarea {
    width: 100%;
    height: 100px;
    margin-bottom: 8px;
    padding: 12px;
    font-size: 16px;
    border-radius: 8px;
    border: none;
    outline: none;
    background: rgba(255, 255, 255, 0.2);
    color: #fff;
    resize: none;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.answer button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    transition: background 0.3s;
}

.answer button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
}

.feedback {
    margin-top: 16px;
    font-size: 18px;
    color: #ff6f61;
}

.navigation {
    margin-top: 16px;
}

.navigation button {
    margin-right: 8px;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    background: linear-gradient(135deg, #667eea, #764ba2);
    color: #fff;
    transition: background 0.3s;
}

.navigation button:hover {
    background: linear-gradient(135deg, #764ba2, #667eea);
}

.fireworks {
    margin-top: 24px;
    font-size: 24px;
    text-align: center;
    color: #ff6f61;
    animation: fireworks 1s infinite;
}

@keyframes fireworks {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
}
