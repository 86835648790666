/* Settings 页面样式 */
.settings-container {
    margin-top: 20px;
}

.settings-form {
    margin-top: 20px;
}

.edit-button[disabled] {
    pointer-events: none;
    cursor: not-allowed;
}

/* 覆盖“个人设置”选中状态和悬停时的背景颜色 */
.settings-menu .ant-menu-item-selected,
.settings-menu .ant-menu-item:hover {
    background-color: transparent !important;
    color: inherit !important; /* 保持原有文字颜色 */
}