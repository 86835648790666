/* 全局样式 */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #fff;
    overflow: hidden;
}

/* 侧边栏样式 */
.ant-layout-sider {
    background-color: #fff;
    position: relative; /* 确保子元素可以绝对定位 */
}

.ant-menu-light .ant-menu-item:hover {
    background-color: #f0f0f0 !important;
}

.ant-menu-item-selected .anticon {
    color: #090909 !important; /* 修改选中状态图标颜色 */
}

/* 分割线 */
.ant-layout {
    border-left: 1px solid #f0f0f0;
}

.ant-layout-header {
    border-bottom: 1px solid #f0f0f0;
    display: flex;
    justify-content: flex-end;
    align-items: center; /* 垂直居中 */
    padding-right: 16px; /* 调整登录按钮右侧对齐 */
}

/* 隐藏默认margin */
.ant-layout-content {
    margin: 0 !important;
    padding-top: 0; /* 修改顶部 padding 参数为 0 */
}

/* 控制按钮样式 */
.toggle-button {
    position: absolute;
    bottom: 0;
    width: 100%;
    cursor: pointer;
}

.toggle-button .ant-menu-item {
    background-color: #fff !important; /* 背景颜色为白色 */
}

.toggle-button:hover .ant-menu-item {
    background-color: #f0f0f0 !important; /* hover 时展示浅灰色 */
}

.header-custom {
    background-color: #fff;
    display: flex;
    justify-content: center; /* Center contents horizontally */
    align-items: center;
    padding: 0 16px 0 0;
    width: 100%;
}

.login-container {
    display: flex;
    justify-content: center; /* Center the message */
    align-items: center;
    width: 100%;
    position: relative; /* Position relative to contain absolute positioned button */
}

.login-prompt {
    color: red; /* Change font color to red */
}

.auth-buttons {
    position: absolute;
    right: 16px; /* Position the buttons to the right */
    display: flex;
    gap: 10px; /* Space between the buttons */
}

.login-container .ant-btn-primary {
    position: absolute;
    right: 16px; /* Position the button to the right */
}




