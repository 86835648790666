.auth-container {
    margin-top: 20px;
}

.auth-form {
    margin-top: 20px;
}

.captcha-container {
    margin-left: -57px;
    background-color: white;
    border: 1px solid #d9d9d9;
    padding: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1001;  /* 确保验证码蒙层在注册蒙层之上 */
}

.captcha-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.send-code-button {
    padding: 0;
    height: 30px;
    line-height: 30px;
    width: 60px;  /* 调整宽度 */
    margin: 0;  /* 去掉按钮左右的间距 */
    border: none;  /* 移除按钮的边框 */
    background: none;  /* 移除按钮的背景 */
    box-shadow: none;  /* 移除按钮的阴影 */
    cursor: pointer;  /* 设置鼠标样式 */
    text-align: center;  /* 文本居中 */
}

.ant-input-group-addon:hover {
    background-color: #1677ff;  /* hover 时背景颜色置为蓝色 */
    padding: 0;
    color: white;
}

/* 修改发送按钮所在的span标签左右padding为0 */
.ant-input-group-addon {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

