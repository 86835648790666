.tip-notification {
    position: fixed;
    bottom: 150px; /* 根据需要调整位置 */
    right: 30px; /* 根据需要调整位置 */
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    z-index: 1000;
    font-size: 14px;
    max-width: 200px;
    word-wrap: break-word;
    white-space: normal;
}
