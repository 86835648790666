.loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 60px);
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: all;
    user-select: none;
}

.chat-container {
    display: flex;
    flex-direction: column;
    height: 90vh;
    overflow: hidden;
    position: relative;
}

.chat-tabs {
    display: flex;
    gap: 8px;
    margin-bottom: 10px;
}

.chat-tab {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background: #eeeeee;
    border-radius: 4px;
    position: relative;
}

.chat-tab.active {
    background: #d0e4f2;
}

.chat-tab-title {
    border: none;
    background: transparent;
    width: 80px;
    outline: none;
}

.chat-tab-title:focus {
    outline: none;
    border-bottom: 1px solid #1890ff;
}

.chat-tab {
    margin-left: 4px;
    cursor: pointer;
}

.chat-tab .delete-icon {
    display: none;
    position: absolute;
    right: -4px;
    top: -4px;
}

.chat-tab:hover .delete-icon {
    display: block;
}

.chat-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;
}

.chat-display {
    flex-grow: 1;
    height: 400px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    overflow-y: auto;
    background-color: #f0f0f0;
    position: relative; /* 添加这一行 */
}

.message-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.user-message,
.ai-message {
    display: inline-block;
    max-width: 60%;
    padding: 10px 15px;
    margin: 5px 0;
    border-radius: 25px;
    word-wrap: break-word;
    word-break: break-word;
    position: relative;
}

.user-message {
    align-self: flex-end;
    background-color: #cfeaf5;
    text-align: right;
    margin-left: auto;
}

.ai-message {
    align-self: flex-start;
    background-color: #eac5c5;
    text-align: left;
}

.user-message::before,
.ai-message::before {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.user-message::before {
    content: "用户:";
    text-align: right;
    display: block;
}

.ai-message::before {
    content: "AI:";
    text-align: left;
    display: block;
}

.chat-input-row {
    position: relative;
}

.input-col {
    position: relative;
    display: flex;
    align-items: center;
}

.input-col .ant-input-textarea {
    height: 100px;
    overflow-y: auto;
}

.send-button {
    position: absolute;
    top: 4px;
    right: 4px;
}

/*.clear-button {*/
/*    position: fixed;*/
/*    bottom: 145px;*/
/*    right: 1220px;*/
/*    z-index: 1000;*/
/*    background-color: rgba(255, 255, 255, 0.8);*/
/*}*/

.login-prompt-container {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    height: 60px; /* 与登录按钮的高度相同 */
}

.login-prompt {
    margin-right: 10px; /* 根据需要调整间距 */
}

.code-block {
    background-color: #000;
    color: #fff;
    padding: 0px;
    border-radius: 8px; /* 改为圆角 */
    position: relative;
    margin-bottom: 10px;
}

.code-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #434141; /* 设置顶部行颜色为浅灰色 */
    padding: 5px 10px;
    border-top-left-radius: 8px; /* 改为圆角 */
    border-top-right-radius: 8px; /* 改为圆角 */
    margin: 0; /* 移除默认的顶部边距 */
}

.language {
    font-weight: bold;
    padding: 5px 0;
}

.copy-button {
    background-color: #333; /* 设置背景颜色 */
    color: #fff; /* 设置字体颜色 */
    border: none;
    padding: 5px 10px;
    border-radius: 4px; /* 设置圆角 */
}

pre {
    background-color: #000;
    color: #fff;
    padding: 5px 5px; /* 增加左右间隔 */
    border-radius: 8px; /* 改为圆角 */
    overflow: auto;
    margin-top: 0; /* 移除默认的顶部间距 */
}
.clear-button {
    position: absolute;
    bottom: 113px;
    left: 5px;
    z-index: 1000;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
}

.clear-button .anticon {
    font-size: 16px;
    color: #ef0a15;
}

.no-chat-message {
    text-align: center;
    margin-top: 20px;
    color: #888;
    font-size: 18px; /* 增大字体 */
    font-weight: bold; /* 加粗字体 */
}
