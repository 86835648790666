.experiment-page {
    padding: 24px;
    background-color: #f5f5f5;
}

.experiment-page h1 {
    font-size: 24px;
    margin-bottom: 16px;
}

.experiment-page p {
    font-size: 16px;
    color: #333;
}
